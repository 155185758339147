export class NumberRange {
    constructor(rand: number = 0) {
        this.reset(rand);
    }
    start: number;
    end: number;
    range: string;
    rangeNoSpace: string;
    key: string;
    count: number;

    findStartNumber(num: number) {
        const batch = 50
        let rangeStart = 0
        if (num <= batch) {
            rangeStart = 0
        } else {
            let multi = Math.floor(num / batch)
            if (num == (multi * batch)) {
                rangeStart = ((multi - 1) * batch) + 1
            }
            else {
                rangeStart = (multi * batch) + 1
            }
        }
        return rangeStart;
    }

    reset(rand: number) {
        this.start = this.findStartNumber(rand);
        const increment = (this.start == 0) ? 50 : 49;
        this.end = this.start + increment;
        this.range = this.start.toString() + " - " + this.end.toString();
        this.rangeNoSpace = this.start.toString() + "-" + this.end.toString();
        this.key = "range_" + this.start.toString() + "_" + this.end.toString()
        this.count = (this.start == 0) ? 51 : 50;
    }

    previousRange(): NumberRange {
        if (this.start == 0) {
            return new NumberRange(0);
        } else {
            let startNum = (this.start == 51) ? 0 : (this.start - 50);
            return new NumberRange(startNum);
        }
    }

    nextRange(): NumberRange {
        return new NumberRange(this.end + 1);
    }
}