import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
  selector: 'app-homefive-courses',
  templateUrl: './homefive-courses.component.html',
  styleUrls: ['./homefive-courses.component.scss']
})
export class HomefiveCoursesComponent implements OnInit {
  languageList: LanguageList;

  constructor(private nolApiSvc: NolApiService) { }

  ngOnInit(): void {
    this.populateLanauges();
  }

  populateLanauges() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    if (this.languageList && this.languageList.all.length > 0) {
      // process if any
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        this.languageList = new LanguageList(data);
        this.nolApiSvc.setCacheLanguageList(data);
      })
    }
  }
}
