import { PatternWords } from "./pattern-words";

export class MixMatchCol {
    constructor(pos: number, patternList: PatternWords[]) {
        this.position = pos;
        this.patterns = patternList;
        this.value = 0;
    }
    position: number;
    visible: boolean = false;
    patterns: PatternWords[];
    value: number;

    hide() {
        this.visible = false;
        this.value = 0;
    }
    show() {
        this.visible = true;
    }
}

export class MixMatchRow {
    constructor(id?: number) {
        if (id) {
            this.id = id;
        }
        this.columns = [];
    }
    id: number = 0;
    total: number = 0;
    visible: boolean = false;
    columns: MixMatchCol[];

    addCol(pos: number, patternList: PatternWords[]) {
        this.columns.push(new MixMatchCol(pos, patternList));
    }

    col(pos: number): MixMatchCol {
        return this.columns.find(val => val.position == pos);
    }

    hideAllColAfter(colPos: number) {
        let colFound = false;
        this.columns.forEach(col => {
            if (colFound) {
                col.hide();
            }
            if (col.position == colPos) {
                colFound = true;
            }
        })
    }

    showAllColAfter(colPos: number) {
        let colFound = false;
        this.columns.forEach(col => {
            if (colFound) {
                col.show();
            }
            if (col.position == colPos) {
                colFound = true;
            }
        })
    }

    manageColumns(colPos: number, patternPart: string) {
        switch (patternPart) {
            case "entire":
                this.hideAllColAfter(colPos);
                break;
            case "prefix":
                this.showAllColAfter(colPos);
                break;
            case "suffix":
                this.showAllColAfter(colPos);
                break;
            case "next":
                this.hideAllColAfter(colPos);
                break;
        }
    }

    disable() {
        // ToDo: make gray color
    }

    highlight() {
        // ToDo:  make bright color
    }

    hideAll() {
        this.visible = false;
        this.columns.forEach(col => {
            col.visible = false;
        })
    }

    showAll() {
        this.visible = true;
        this.columns.forEach(col => {
            col.visible = true;
        })
    }

    assignNumber(colPos: number, num: number) {
        switch (colPos) {
            case 1:
                this.col(1).value = num;
                break;
            case 10:
                const ten = Math.floor(num / 10) * 10
                this.col(10).value = ten;
                break;
            case 100:
                const hund = Math.floor(num / 100) * 100
                this.col(10).value = hund;
                break;
        }
    }

    calculateTotal() {
        let tot = 0;
        // tot += (this.throusands >= 0) ? this.throusands : 0;
        // tot += (this.hundreds >= 0) ? this.hundreds : 0;
        // tot += (this.tens >= 0) ? this.tens : 0;
        // tot += (this.ones >= 0) ? this.ones : 0;
        this.columns.forEach(col => {
            // checking to avoid -1 
            if (col.value > 0) {
                tot += col.value;
            }
        })
        this.total = tot;
    }

    clear() {
        this.id = 0;
        this.total = -1;
        this.columns = [];
    }
}