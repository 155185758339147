<div class="hero-banner bg-white">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="row pt-1 align-items-center justify-content-center" style="text-align:center;">
                            <span class="sub-title">Select a language and type a number</span>
                            <div style="color: blue;" style="text-align:center;width: 50%" *ngIf="languageList">
                                <select class="form-select" style="background-color:deepskyblue"
                                    (change)="selectLanguageById($event.target.value)">
                                    <option *ngFor="let lang of languageList.all" value="{{lang.language_id}}">
                                        <span *ngIf="lang">{{lang.language_name}}</span>
                                    </option>
                                </select>
                                <div *ngIf="selectedLanguage">
                                    <audio #player autobuffer="autobuffer" autoplay="autoplay" [src]="audioBase64"
                                        style="width: 80%;">
                                        Your browser does not support the audio element / audio format.
                                    </audio>
                                    <!-- controls="controls"  -->
                                    <!-- <source src="{{audioBase64}}" /> -->
                                    <!-- <source [src]="getAudioAsBase64" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="hero-banner-image text-center">
                        <img src="assets/img/banner-img3.jpg" alt="image">
                        <div class="row pt-1 align-items-center justify-content-center" 
                            *ngIf="audioLanguageCode!='en-US'" style="padding: 20px; text-align: center;">
                            <div class="col-sm-6 text-center" >
                                <label class="text-secondary small">{{numAsEnglishWords}}</label>
                            </div>
                        </div>
                        <div class="row pt-2 align-items-center justify-content-center">
                            <div class="col-sm-6 text-center"  style="text-align:center;">
                                <label class="text-danger"
                                    style="font-weight:bold;">{{numAsWordsTranslated}}</label>
                            </div>
                        </div>
                        <div class="row pt-4 align-items-center justify-content-center">
                            <div class="col-sm-8 text-center" *ngIf="selectedLanguage">
                                <audio #player autobuffer="autobuffer" autoplay="autoplay" [src]="audioBase64"
                                    style="width: 80%;">
                                    Your browser does not support the audio element / audio format.
                                </audio>
                                controls="controls" 
                                <source src="{{audioBase64}}" />
                                <source [src]="getAudioAsBase64" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <div class="row pt-1 align-items-center justify-content-center" style="text-align:center;">
                        <br><br>
                        <label *ngIf="selectedLanguage && inputNum && inputNum>-1">{{inputNum}} in
                            {{selectedLanguage.language_name}}</label>
                        <!-- <div class="hero-banner-content black-color"> -->
                        <!-- <br><br> -->
                        <label class="text-danger"
                            style="font-weight:bold; font-size: large;">{{numAsWordsTranslated}}</label>
                        <br><br><br>
                        <!-- <img src="assets/images/icons8-speaker-50.png" width="20" height="20" alt="Speak"> -->
                        <button class="round-button-female" type="submit" (click)="speakFemale()" *ngIf="currentWords">
                            <img class="button-icon" *ngIf="currentWords && currentWords.audio_female_processing"
                                src="assets/images/icon/process.gif" alt="...">
                            <img class="button-icon" *ngIf="!currentWords || !currentWords.audio_female_processing"
                                src="assets/images/speaker-pink.png" alt="Speak" style="cursor:pointer;">
                        </button>
                        &nbsp;
                        <button class="btn btn-primary" type="submit" *ngIf="!currentWords" (click)="translate()"
                            style="width:200px;">
                            <span *ngIf="!translating"><img class="button-icon" src="assets/images/icon/translate.png"
                                    alt="Speak" style="cursor:pointer;"> Translate</span>
                            <span *ngIf="translating"><img class="button-icon" *ngIf="translating"
                                    src="assets/images/icon/process.gif" alt="..."> Translating...</span>
                        </button>
                        <!-- &nbsp;
                        <button class="round-button-male" type="submit" (click)="speakMale()" *ngIf="currentWords"><img
                                class="button-icon" *ngIf="currentWords && currentWords.audio_male_processing"
                                src="assets/images/icon/process.gif" alt="...">
                            <img class="button-icon" *ngIf="!currentWords || !currentWords.audio_male_processing"
                                src="assets/images/speaker-blue.png" alt="Speak" style="cursor:pointer;"></button> -->
                        <br><br>
                        <!-- <br><br>
                        <button class="default-btn" type="submit" (click)="sayNumber()"><img
                                src="assets/images/icons8-speaker-50.png" width="20" height="20"
                                alt="Speak">&nbsp;&nbsp;&nbsp;Say</button> -->

                        <!-- <button class="btn btn-success" type="submit" (click)="sayNumber()"><img
                            src="assets/image/nol-circle.png" width="50" height="50" alt="Speak"></button> -->


                        <!-- <span class="sub-title">We Challenge Young Minds</span>
                        <h1>A modern, global courses for a Modern World!</h1>
                        <p>Raque supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                        <div class="btn-box">
                            <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                            <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                        </div> -->
                        <!-- </div> -->
                        <!-- <div class="hero-banner-content black-color"> -->

                        <!-- </div> -->
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="row pt-1 align-items-center justify-content-center" style="text-align:center;">
                                <div style="width:50%;">
                                    <input class="form-control" style="text-align:center; border-color: grey;"
                                        type="number" pattern="[0-9]*" (keypress)="keyPressNumbers($event)"
                                        (keyup)="sayNumber()" [(ngModel)]="inputNum" #myInput (click)="myInput.select()"
                                        maxlength="15" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>