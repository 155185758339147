<app-header-style-one></app-header-style-one>

<!-- <app-header-style-two></app-header-style-two>
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructor Details</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div> -->

<div class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="assets/images/abacus.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a>
                            </li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a>
                            </li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a>
                            </li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i
                                        class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Interactive Learning Tool</h3>
                        <!-- <span class="sub-title">Angular Teacher</span> -->
                        <p>You can learn numbers in any language by mix and match the prefixes and numbers.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title text-start">
            <h3>Mix & Match from the list</h3>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div style="color: blue;">
                    <div *ngFor="let lang of languageList" style="float:left">
                        <input type="radio" class="btn-check" name="language" id="option7" autocomplete="off">
                        <label class="btn btn-sm text-primary" for="option7"
                            (click)="selectLanguage(lang)">{{lang.language_name}}</label>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row" *ngIf="mixAndMatchOutput">
            <div class="col-lg-6 col-md-6">
                <span>{{mixAndMatchOutput.total}}</span>
                <br>
                <!-- <span *ngIf="outputWords">{{outputWords.words}}</span> -->
                <label *ngIf="outputWords" class="text-danger" style="font-weight:bold;">{{outputWords.words}}</label>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="topbar-ordering">
                    <table>
                        <tr *ngIf="mixAndMatch1 && mixAndMatch1.visible">
                            <!-- <td>1's >></td> -->
                            <td *ngIf="mixAndMatch1.col(1) && mixAndMatch1.col(1).visible"> <select class="form-select"
                                    (change)="findTotal(1, 1, listFirstSet, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch1.col(1).patterns" value="{{item.key_number}}">
                                        <span *ngIf="item.key_number>=0">{{item.key_number}} - </span>{{item.words}}
                                    </option>
                                </select></td>
                        </tr>
                        <tr *ngIf="mixAndMatch2 && mixAndMatch2.visible">
                            <!-- <td>10's >></td> -->
                            <td *ngIf="mixAndMatch2.col(10) && mixAndMatch2.col(10).visible"> <select class="form-select"
                                    (change)="findTotal(2, 10, listTensAndPlus, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch2.col(10).patterns" value="{{item.key_number}}">
                                        <span *ngIf="item.key_number>=0">{{item.key_number}} - </span>{{item.words}}
                                    </option>
                                </select></td>
                            <td *ngIf="mixAndMatch2.col(1) && mixAndMatch2.col(1).visible"> <select class="form-select"
                                    (change)="findTotal(2, 1, listFirst9, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch2.col(1).patterns" value="{{item.key_number}}">
                                        <span *ngIf="item.key_number>=0">{{item.key_number}} - </span>{{item.words}}
                                    </option>
                                </select></td>
                        </tr>
                        <!-- <tr *ngIf="mixAndMatch3 && mixAndMatch3.visible">
                            <td>100's >></td>
                            <td *ngIf="mixAndMatch3.col(100) && mixAndMatch3.col(100).visible"> <select class="form-select"
                                    (change)="findTotal(3, 100, listHundAndPlus, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch3.col(100).patterns" value="{{item.key_number}}">
                                        <span *ngIf="item.key_number>=0">{{item.key_number}} - </span>{{item.words}}
                                    </option>
                                </select></td>
                            <td *ngIf="mixAndMatch3.col(10) && mixAndMatch3.col(10).visible"> <select class="form-select"
                                    (change)="findTotal(3, 10, listTensAndPlus, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch3.col(10).patterns" value="{{item.key_number}}">
                                        <span *ngIf="item.key_number>=0">{{item.key_number}} - </span>{{item.words}}
                                    </option>
                                </select></td>
                            <td *ngIf="mixAndMatch3.col(1) && mixAndMatch3.col(1).visible"> <select class="form-select"
                                    (change)="findTotal(3, 1, listFirst9, $event.target.value)">
                                    <option *ngFor="let item of mixAndMatch3.col(1).patterns" value="{{item.key_number}}">
                                        {{item.key_number}} {{item.words}}
                                    </option>
                                </select></td>
                        </tr> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>