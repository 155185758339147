import { NumberRange } from "./number-range";

export class NumberRangeCollection {
    constructor(range: NumberRange) {
        this.current = range;
        this.findNext5();
    }

    current: NumberRange;
    previous: NumberRange;
    previous5: NumberRange[];
    next: NumberRange;
    next5: NumberRange[];

    findNext5() {
        this.next5 = [];
        const next1 = this.current.nextRange();
        this.next5.push(next1);
        const next2 = next1.nextRange();
        this.next5.push(next2);
        const next3 = next2.nextRange();
        this.next5.push(next3);
        const next4 = next3.nextRange();
        this.next5.push(next4);
        const next5 = next4.nextRange();
        this.next5.push(next5);
    }
}