<app-header-style-one></app-header-style-one>
<br><br>
<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Get in Touch</span>
            <h2>Like to contact us or provide a feedback?</h2>
            <p>Please submit your details, feedback and comments.</p>
            <!-- <br>Required fields are marked *</p> -->
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image">
                    <img src="assets/images/helpdesk.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" (ngSubmit)="submitContactForm()" #contactUsForm="ngForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" class="form-control" id="name" required
                                        data-error="Please enter your name" placeholder="Your name"
                                        [(ngModel)]="this.contUsForm.userName">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" class="form-control" id="email" required
                                        data-error="Please enter your email" placeholder="Your email address"
                                        [(ngModel)]="this.contUsForm.userEmail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" class="form-control" id="phone_number"
                                        required data-error="Please enter your phone number"
                                        placeholder="Your phone number" [(ngModel)]="this.contUsForm.userPhone">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" id="message" class="form-control" cols="30" rows="6"
                                        required data-error="Please enter your message"
                                        placeholder="Write your message..."
                                        [(ngModel)]="this.contUsForm.message"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="!contactUsForm.form.valid">Send
                                    Message</button>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <label></label>
                            </div> -->

                            <!-- <p> -->
                            <span><small><i>**Your email address & phone number will not be published or
                                    shared.</i></small></span>
                            <!-- </p> -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Our Address</h3>
                    <p>California, United States</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Contact</h3>
                    <p>Mobile: <a href="tel:+44457895789">(+44) - 45789 - 5789</a></p>
                    <p>E-mail: <a href="mailto:numberoutloud@gmail.com">numberoutloud@gmail.com</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box">
                    <div class="back-icon">
                        <i class='bx bx-time-five'></i>
                    </div>
                    <div class="icon">
                        <i class='bx bx-time-five'></i>
                    </div>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 07:00 - 20:00 PST</p>
                    <p>Sunday & Saturday: 08:00 - 17:00 PST</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
</div> -->