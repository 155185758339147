<app-header-style-one></app-header-style-one>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <h2>Learn numbers using Flashcards</h2>
            <div class="row align-items-center" *ngIf="languageList">
                <div class="col-md-6 col-lg-8">
                    <span>Select a language</span>
                    <select class="form-select" (change)="selectLanguage($event.target.value)">
                        <option *ngFor="let lang of languageList.all" value="{{lang.language_id}}"
                            [selected]="lang.language_id==currentLanguage.language_id">
                            {{lang.language_name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-12">
                <div class="row">
                    <h5>Move your mouse over the card to flip</h5>
                    <div class="flip-card" *ngFor="let item of currentSet">
                        <div class="flip-card-inner" *ngIf="item">
                            <div class="flip-card-front">
                                <br><br>
                                <p>How to read</p>
                                <div>{{item.key_number}}</div>
                                <br>
                                <p>in {{currentLanguage.language_name}}</p>
                            </div>
                            <div class="flip-card-back">
                                <br><br><br>
                                <div>{{item.words}}</div>
                                <br>
                                <div><small>{{item.phonetics_english}}</small></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <aside class="widget-area">
                    <!-- <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 10, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Making Peace With The Feast Or
                                        Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 21, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">I Used The Web For A Day On A 50
                                        MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How To Create A Responsive Popup
                                        Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div> -->
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Other Tools</h3>
                        <ul>
                            <li><a routerLink="/tools/compare" target="_blank">Compare 2 Languages </a></li>
                            <li><a routerLink="/tools/interactive" target="_blank">Generate by Pattern </a></li>
                        </ul>
                    </div>
                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Raque <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div> -->
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Numbers in All Languages</h3>
                        <h5 class="chapter">Indian</h5>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.indian">
                                    <a routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                        <br>
                        <h5 class="chapter">Europian</h5>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.european"> <a
                                        routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                        <br>
                        <h5 class="chapter">English</h5>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.english"> <a routerLink="/numbers/{{lang.language_id}}"
                                        target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="courses-sidebar-syllabus" *ngIf="languageList">
                        <h3>Other Languages</h3>
                        <span class="chapter">Indian</span>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.indian">
                                    <a routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                        <span class="chapter">Europian</span>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.european"> <a
                                        routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                        <span class="chapter">English</span>
                        <div class="courses-list">
                            <ul>
                                <li *ngFor="let lang of languageList.english"> <a routerLink="/numbers/{{lang.language_id}}"
                                        target="_blank">
                                        {{lang.language_name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>                     -->
                    <div class="courses-purchase-info">
                        <h4>Like to translate more numbers?</h4>
                        <p>You can translate any number to any language using our Live Translator.</p>
                        <a routerLink="/translator" class="default-btn"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Try
                                Translator</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>                    
                    <!-- <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div> -->
                </aside>
            </div>
        </div>
    </div>
</div>