<header class="header-area p-relative">
    <!-- <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-contact-info">
                        <li>
                            Call: 
                            <a href="tel:+502464674">+502 464 674</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-login-register">
                        <li><a routerLink="/login"><i class='bx bx-log-in'></i> Login</a></li>
                        <li><a routerLink="/register"><i class='bx bx-log-in-circle'></i> Register</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
    <div class="navbar-area navbar-style-three" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/images/nol-logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link">Home</a>
                            <!-- <i class='bx bx-chevron-down'></i> -->
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Portal</a></li>
                                <li class="nav-item"><a routerLink="/course-portal-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Course Portal Online</a></li>
                                <li class="nav-item"><a routerLink="/distant-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Distant Learning</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Popular Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/modern-teaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Modern Teaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-schooling" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Schooling</a></li>
                                        <li class="nav-item"><a routerLink="/learning-school-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Learning School Online</a></li>
                                        <li class="nav-item"><a routerLink="/remote-training-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Remote Training Online</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">New Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/business-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/health-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Health Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/language-school" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Language School</a></li>
                                        <li class="nav-item"><a routerLink="/gym-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gym Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-motivation-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Motivation Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-education-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-training-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Training Course</a></li>
                                    </ul>
                                </li>
                            </ul> -->
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Tools <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/tools/interactive" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Interactive
                                        Learning</a></li> -->
                                <li class="nav-item"><a routerLink="/tools/compare" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Compare 2
                                        Languages</a></li>
                                <li class="nav-item"><a routerLink="/tools/flashcards" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Flashcards</a></li>
                                <!-- <li class="nav-item"><a routerLink="/tutorials/learn-numbers/spanish-us"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Tamil (India)</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="/tutorials/learn-numbers/german" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Learn
                                        numbers in German</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Learn in Indian Languages <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/tutorials/learn-numbers/hindi-in"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Hindi</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-2" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Malayalam</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/tutorials/compare-languages" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Compare two
                                        languages</a></li> -->
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Number List <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">European
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.european"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">Indian
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.indian"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">Other Asian
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.asian"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">Americas
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.americas"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">Middle East
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.middleeast"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">English
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.english"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item" *ngIf="languageList"><a href="#" class="nav-link">Others
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" *ngFor="let lang of languageList.others"><a
                                                (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <!-- <li class="nav-item" *ngFor="let lang of languageList.all">
                                    <a (click)="gotoPage(lang)" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">{{lang.language_name}}</a>
                                </li> -->
                                <a routerLink="/numbers" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">
                                    <div style="color:gray">All Languages</div>
                                </a>

                                <!-- <li class="nav-item"><a routerLink="/numbers/malayalam" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Malayalam
                                        (India)</a></li>
                                <li class="nav-item"><a routerLink="/numbers/tamil" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tamil
                                        (India)</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="/numbers/spanish" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Translated
                                        Numbers in Spanish</a></li>
                                <li class="nav-item"><a routerLink="/numbers/german" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Learn
                                        numbers in German</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Translated Numbers in Indian Languages
                                        <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/numbers/hindi" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Hindi</a></li>
                                        <li class="nav-item"><a routerLink="/numbers/tamil" class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Tamil</a></li>
                                        <li class="nav-item"><a routerLink="/numbers/malayalam"
                                                class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Malayalam</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/tutorials/compare-languages" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Compare two
                                        languages</a></li> -->
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Help <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">About</a></li> -->
                                <!-- <li class="nav-item"><a routerLink="/docs/rapidapi" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">RapidAPI Docs</a></li> -->
                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <!-- <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ger</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Fre</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Spa</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/russia-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Rus</span>
                            </a>
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag">
                                <span>Ita</span>
                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>01</span>
                    </a>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                </div> -->
            </nav>
        </div>
    </div>
</header>

<!-- <div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div> -->