import { NumberRange } from "./number-range";
import { TranslatedWords } from "./translated-words";

export class TranslatedComparisonItem {
    number: number;
    left: TranslatedWords;
    right: TranslatedWords;
    processingLeft: boolean = false;
    processingRight: boolean = false;
    constructor(num: number) {
        this.number = num;
    }

    clearLeft() {
        this.clear(this.left);
    }

    clearRight() {
        this.clear(this.right);
    }

    clear(item: TranslatedWords) {
        if (item) {
            // item['key_number'] = -1;
            // item['words'] = "...";
            if (item.key_number) item.key_number = -1;
            if (item.words) item.words = null;
            if (item.has_audio_female) item.has_audio_female = false;
            if (item.has_audio_male) item.has_audio_male = false;
            if (item.audio_female) item.audio_female = null;
            if (item.audio_male) item.audio_male = null;
            if (item.audio_female_processing) item.audio_female_processing = false;
            if (item.audio_male_processing) item.audio_male_processing = false;
            if (item.has_tip) item.has_tip = false;
            if (item.tip_desc) item.tip_desc = null;
        }
    }
}

export class TranslatedComparisonCollection {
    range: NumberRange;
    itemsCount = 50;
    items: TranslatedComparisonItem[] = [];
    constructor(numRange: NumberRange) {
        this.range = numRange;
        // this.refresh(numRange);
        this.initItems();
    }

    initItems() {
        for (let i = this.range.start; i < this.range.count; i++) {
            this.items.push(new TranslatedComparisonItem(i));
        }
        // if (this.range.count == 50) {
        //     this.items.push(new TranslatedComparisonItem(-1));
        // }
    }

    assignLeft(tw: TranslatedWords) {
        let item = this.items.find(obj => obj.number == tw.key_number);
        if (item) {
            item.left = tw;
        }
    }

    assignRight(tw: TranslatedWords) {
        let item = this.items.find(obj => obj.number == tw.key_number);
        if (item) {
            item.right = tw;
        }
    }

    resetRange(newRange: NumberRange) {
        this.range = newRange;
        let statNum = this.range.start;
        this.items.forEach(item => {
            item.number = statNum;
            item.clearLeft();
            item.clearRight();
            statNum++;
        })
        // make last item null if range is not 0-50
        if (this.range.start > 50) {
            this.items[this.items.length - 1].number = -1;
        }
    }

    clearLeft() {
        this.items.forEach(item => {
            item.clearLeft();
        })
    }

    clearRight() {
        this.items.forEach(item => {
            item.clearRight();
        })
    }

}