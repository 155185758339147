<app-header-style-one></app-header-style-one>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Compare numbers in two languages</h2>
                        <p>Comparing numbers in two different languages is an effective way to learn both languages</p>
                    </div>
                    <!-- <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Language</span>
                                <a>{{languageLeft.language_name}}</a>
                            </li>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Country</span>
                                <a>{{languageLeft.country_name}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Numbers Processed</span>
                                <a>{{languageLeft.words_ready_upto}} & Counting</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">10/23/2022</a>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <!-- <div class="col-lg-4">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>
                        <div class="price">$250</div>
                        <a routerLink="/translator" class="default-btn"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Try
                                Translator</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row" *ngIf="languageLeft">
            <div class="col-lg-8">
                <!-- <div class="courses-details-image text-center">
                    <img src="{{languageLeft.banner_image_src}}" alt="image">
                    <small *ngIf="languageLeft.banner_image_credits" style="float: right;">Image &copy; <a
                            href="{{languageLeft.banner_image_credits_link}}"
                            target="_blank">{{languageLeft.banner_image_credits_name}}</a></small>
                </div> -->
                <div class="courses-details-desc">
                    <!-- <h4>Select a languge</h4> -->
                    <div class="why-you-learn">

                        <audio #player autobuffer="autobuffer" autoplay="autoplay" [src]="audioBase64"
                            style="width: 80%;">
                            Your browser does not support the audio element / audio format.
                        </audio>

                        <table class="table nol-table" *ngIf="numberList">
                            <!-- <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{{languageLeft.language_name}} Translation</th>
                                    <th scope="col">Speak</th>
                                </tr>
                            </thead> -->
                            <tbody>
                                <tr *ngIf="languageList" style="border-color: white;">
                                    <td colspan="2"><select class="form-select"
                                            (change)="selectLangLeft($event.target.value)">
                                            <option *ngFor="let lang of languageList.all" value="{{lang.language_id}}"
                                                [selected]="lang.language_id==languageLeft.language_id">
                                                {{lang.language_name}}
                                            </option>
                                        </select></td>
                                    <td></td>
                                    <td colspan="2"><select class="form-select"
                                            (change)="selectLangRight($event.target.value)">
                                            <option *ngFor="let lang of languageList.all" value="{{lang.language_id}}"
                                                [selected]="lang.language_id==languageRight.language_id">
                                                {{lang.language_name}}
                                            </option>
                                        </select></td>
                                </tr>
                                <tr>
                                    <td colspan="2" *ngIf="languageLeft" style="text-align:right;">
                                        <h5 class="title-left">{{languageLeft.language_name}}<span
                                                *ngIf="!languageLeft.language_name.includes(languageLeft.country_name)">
                                                ({{languageLeft.country_name}})</span></h5>
                                        <!-- <strong>
                                            {{languageLeft.language_name}}
                                            <span
                                                *ngIf="!languageLeft.language_name.includes(languageLeft.country_name)">
                                                ({{languageLeft.country_name}})</span>
                                        </strong> -->
                                    </td>
                                    <td></td>
                                    <td colspan="2" *ngIf="languageRight">
                                        <h5 class="title-right">
                                            {{languageRight.language_name}}<span
                                                *ngIf="!languageRight.language_name.includes(languageRight.country_name)">
                                                ({{languageRight.country_name}})</span></h5>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of numberList.items">
                                    <td *ngIf="item.left && item.number>=0">
                                        <div *ngIf="item.left.words">
                                            <img *ngIf="item.left.audio_female_processing"
                                                src="assets/images/process.gif" width="20" height="20" alt="...">
                                            <img *ngIf="!item.left.audio_female_processing"
                                                src="assets/images/speaker-pink.png" width="20" height="20" alt="Speak"
                                                (click)="speakFemale(item.left)" style="cursor:pointer;">
                                        </div>
                                    </td>
                                    <td *ngIf="item.left && item.number>=0" style="text-align:right;">
                                        <img *ngIf="item.processingLeft" src="assets/images/process.gif" width="20"
                                            height="20" alt="...">
                                        <div *ngIf="!item.processingLeft">
                                            <div *ngIf="item.left.words">{{item.left.words}}</div>
                                            <div *ngIf="!item.left.words" class="translate-button">
                                                <a (click)="translateLeft(item)">Click to
                                                    view</a>
                                            </div>
                                            <span *ngIf="item.left.has_tip">
                                                <small style="color:read;"><i>(Hint: {{item.left.tip_desc}})</i></small>
                                            </span>
                                        </div>
                                    </td>
                                    <td *ngIf="item && item.number>=0"
                                        style="text-align:center; font-weight: bold; color: blue;">
                                        {{item.number}}
                                    </td>
                                    <td *ngIf="item.right && item.number>=0">
                                        <img *ngIf="item.processingRight" src="assets/images/process.gif" width="20"
                                            height="20" alt="...">
                                        <div *ngIf="!item.processingRight">
                                            <div *ngIf="item.right.words">{{item.right.words}}</div>
                                            <div *ngIf="!item.right.words" class="translate-button">
                                                <a (click)="translateRight(item)">Click to
                                                    view</a>
                                            </div>
                                            <span *ngIf="item.right.has_tip">
                                                <small style="color:read;"><i>(Hint: {{item.right.tip_desc}})</i></small>
                                            </span>
                                        </div>
                                    </td>
                                    <td *ngIf="item.right && item.number>=0">
                                        <div *ngIf="item.right.words">
                                            <img *ngIf="item.right.audio_female_processing"
                                                src="assets/images/process.gif" width="20" height="20" alt="...">
                                            <img *ngIf="!item.right.audio_female_processing"
                                                src="assets/images/speaker-pink.png" width="20" height="20" alt="Speak"
                                                (click)="speakFemale(item.right)" style="cursor:pointer;">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- <button (click)="gotoNextPage()" >Next</button> -->
                        <!-- <a routerLink="/numbers/hi-in/401" class="default-btn"><i -->
                        <a (click)="gotoPreviousPage()" class="default-btn" type="submit"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Previous</span><i
                                class="bx bx-paper-plane icon-arrow after"></i></a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a (click)="gotoNextPage()" class="default-btn" type="submit"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Next</span><i
                                class="bx bx-paper-plane icon-arrow after"></i></a>

                    </div>
                </div>
                <!-- <div class="related-courses">
                    <h3>Related Courses</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img
                                            src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT
                                            Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img
                                            src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design
                                            with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <h3>Next set of numbers</h3>
                    <ul>
                        <li *ngFor="let eachRange of ranges.next5">
                            <a (click)="refreshPage(eachRange)" style="cursor:pointer;">
                                <span>{{eachRange.range}}</span></a>
                        </li>
                    </ul>
                </div>
                <div class="courses-sidebar-syllabus">
                    <h3>Other Tools</h3>
                    <div class="courses-list">
                        <ul>
                            <li><a routerLink="/tools/interactive" target="_blank">Generate by Pattern </a></li>
                            <li><a routerLink="/tools/flashcards" target="_blank">Flashcards</a></li>
                        </ul>
                    </div>
                </div>                
                <!-- <div class="courses-sidebar-syllabus" *ngIf="languageList">
                    <h3>Other Languages</h3>
                    <span class="chapter">Indian</span>
                    <h4>Lessons</h4>
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.indian">
                                <a routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">Europian</span>
                    <h4>Lessons</h4>
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.europian"> <a
                                    routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">English</span>
                    <h4>Final Quiz</h4>
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.english"> <a routerLink="/numbers/{{lang.language_id}}"
                                    target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                            <li>English (US)</li>
                            <li>English (Australia)</li>
                            <li>English (UK)</li>
                            <li>English (India)</li>
                        </ul>
                    </div>
                </div> -->
                <div class="courses-purchase-info">
                    <h4>Like to translate more numbers?</h4>
                    <p>You can translate any number to any language using our Live Translator.</p>
                    <a routerLink="/translator" class="default-btn"><i
                            class='bx bx-paper-plane icon-arrow before'></i><span class="label">Try
                            Translator</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>