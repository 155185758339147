<app-header-style-one></app-header-style-one>
<div class="blog-details-area ptb-100">
    <div class="container">
        <h1>How to use our API hosted in RapidAPI</h1>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>Overview</h3>
                        <p>Now you can translate any numbers to any language using our API. This way you can implepement
                            this feature in your application.</p>
                        <p>You can subscribe NumberOutLoud for free in RapidAPI marketplace. Visit <a href="https://rapidapi.com/OrbitSolve/api/numberoutloud" target="_blank">this page</a> and
                            subscribe. Now you are ready to start using our API..</p>
                        <blockquote class="wp-block-quote">
                            <p>This API made translation of numbers (including audio) super simple with a single REST
                                endpoint</p>
                            <cite>NOL Team</cite>
                        </blockquote>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam
                            eius modi tempora
                            incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <h3>This API provides 2 endpoints:</h3>
                        <ul class="features-list">
                            <li><i class='bx bx-check-double'></i> Languages</li>
                            <li><i class='bx bx-check-double'></i> Translate</li>
                        </ul>
                        <h5>Languages</h5>
                        <p>This endpoint will respond with a list of languages we support. You should the id for the
                            language you need to translate the numbers.</p>
                        <pre><code class="language-markup">GET: https://numberoutloud.p.rapidapi.com/languages</code></pre>
                        <h5>Translate</h5>
                        <p>This endpoint will translate any number to the given lanaguage. You will get numbers
                            translated into the words in provided language as well as Audio as base64 string.</p>
                        <pre><code class="language-markup">POST: https://numberoutloud.p.rapidapi.com/translate</code></pre>
                        <p>Following are the parameters needs to be sent as body</p>
                        <ul>
                            <li>number: (required) Number to be translated</li>
                            <li>language: (required) Language id from the supported language list; <i>refer languages
                                    endpoint</i></li>
                            <li>english: true/false (optional) if you need english words in the response</li>
                            <li>audio: male/female (optional) if you need the audio of translated words </li>
                        </ul>
                        <p>Below is a sample payload</p>
<pre><code class="language-javascript">
        number: 123,
        language: "german",
        english: true,
        audio: "female"
</code></pre>
<p>Below is a sample response</p>
<pre>
    <code>
        "status": "success",
        "data": 
            "number": 123,
            "words": "einhundert-dreiundzwanzig",
            "english": "one hundred and twenty-three",
            "audio": "//NExAAQkZYwAEmElChhI...."
                
    </code>
</pre>
<p>You will receive audio as base64 string</p>
                    </div>
                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a routerLink="/">API</a>,
                            <a routerLink="/">Docs</a>,
                            <a routerLink="/">RapidAPI</a>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a>
                                </li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                </li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>