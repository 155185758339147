import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
  selector: 'app-courses-grid-style-one-columns-three-page',
  templateUrl: './courses-grid-style-one-columns-three-page.component.html',
  styleUrls: ['./courses-grid-style-one-columns-three-page.component.scss']
})
export class CoursesGridStyleOneColumnsThreePageComponent implements OnInit {
  languageList: LanguageList;

  constructor(private nolApiSvc: NolApiService) { }

  ngOnInit(): void {
    this.populateLanauges();
  }

  populateLanauges() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    if (this.languageList && this.languageList.all.length > 0) {
      // process if any
      console.log("got languages from cache!!!")
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        this.languageList = new LanguageList(data);
        this.nolApiSvc.setCacheLanguageList(data);
      })
    }
  }

}
