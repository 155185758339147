import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LanguageDetails } from 'src/app/models/language-details';
import { NumberRange } from 'src/app/models/number-range';
import { NumberRangeCollection } from 'src/app/models/number-range-collection';
import { PatternWords } from 'src/app/models/pattern-words';
import { TranslatedAudio } from 'src/app/models/translated-audio';
import { TranslatedWords } from 'src/app/models/translated-words';
import { NolApiService } from 'src/app/services/nol-api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { TranslatedComparisonItem, TranslatedComparisonCollection } from 'src/app/models/translated-comparison';

@Component({
  selector: 'app-compare-languages',
  templateUrl: './compare-languages.component.html',
  styleUrls: ['./compare-languages.component.scss']
})
export class CompareLanguagesComponent implements OnInit {
  languageLeft: Language;
  languageRight: Language;
  ranges: NumberRangeCollection;
  startNumber = 0;
  currentRange: NumberRange;
  numberList: TranslatedComparisonCollection;
  audioBase64: SafeResourceUrl;
  languageList: LanguageList;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private nolApiSvc: NolApiService) { }

  ngOnInit(): void {
    this.startNumber = 0;
    this.currentRange = new NumberRange(this.startNumber);
    this.populateLanguages();
  }

  populateLanguages() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    if (this.languageList && this.languageList.all.length > 0) {
      this.refreshPage();
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        this.languageList = new LanguageList(data);
        this.nolApiSvc.setCacheLanguageList(data);
        this.refreshPage();
      })
    }
  }

  refreshPage(newRange?: NumberRange) {
    if (newRange) {
      this.currentRange = newRange;
    }
    this.ranges = new NumberRangeCollection(this.currentRange);
    if (this.numberList) {
      console.log("refreshing - " + this.currentRange.start.toString())
      this.numberList.resetRange(this.currentRange);
    } else {
      this.numberList = new TranslatedComparisonCollection(this.currentRange);
    }
    // const langIdLeft = "malayalam"
    // const langIdRight = "tamil"
    // this.populateLanguageLeft(langIdLeft);
    if (!this.languageLeft) {
      this.languageLeft = this.languageList.all[0];
    }
    // this.populateLanguageRight(langIdRight);
    if (!this.languageRight) {
      this.languageRight = this.languageList.all[1];
    }
    // this.refreshPage(this.currentRange);
    this.populateNumbersLeft("both");
    // this.populateNumbersLeft(this.languageLeft.language_id);
    // this.populateNumbersRight(this.languageRight.language_id);
    // console.log(this.numberList);
  }
  /*
    populateLanguageLeft(langId: string) {
      this.nolApiSvc.getLanguageById(langId).subscribe((data: LanguageDetails[]) => {
        if (data && data.length > 0) {
          this.languageLeft = data[0];
          // this.refreshPage();
        }
      })
    }
  
    populateLanguageRight(langId: string) {
      this.nolApiSvc.getLanguageById(langId).subscribe((data: LanguageDetails[]) => {
        if (data && data.length > 0) {
          this.languageRight = data[0];
          // this.refreshPage();
        }
      })
    }
  */

  populateNumbersLeft(side: string) {
    const lang = this.languageLeft;
    this.numberList.clearLeft();

    // if (this.numberList && this.numberList.length > 0) {
    //   if (this.numberList[0].key_number != this.currentRange.start) {
    //     this.numberList = [];
    //   }
    // }

    this.nolApiSvc.getTranslatedWordsByRange(lang.language_id, this.currentRange.key).subscribe((data: TranslatedWords[]) => {
      data.forEach(item => {
        item.language_id = lang.language_id;
        item.language_code = lang.language_code;
        this.numberList.assignLeft(item);
        // let comp = this.numberList.items.find(val => val.key_number == item.key_number);
        // if (comp) {
        //   comp.assignLeft(item);
        // } else {
        //   comp = new TranslatedComparisonItem(item.key_number);
        //   comp.assignLeft(item);
        //   this.numberList.items.push(comp);
        // }
      })
      if (side == "both") {
        this.populateNumbersRight();
      }
    })
  }

  populateNumbersRight() {
    const lang = this.languageRight;
    this.numberList.clearRight();
    // this.numberList.forEach(item => {
    //   item.clearRight();
    // })
    this.nolApiSvc.getTranslatedWordsByRange(lang.language_id, this.currentRange.key).subscribe((data: TranslatedWords[]) => {
      const len = this.numberList.items.length;
      for (let i = 0; i < len; i++) {
        const item = data[i];
        if (item) {
          item['language_id'] = lang.language_id;
          item['language_code'] = lang.language_code;
          this.numberList.assignRight(item);
        }
      }
    })
  }
  /*
    populatePatternTipsTensPlus() {
      this.nolApiSvc.getPatternTipsTensPlus(this.languageLeft.language_id).subscribe((data: PatternWords[]) => {
        this.insertTips(data);
      })
    }
  
    insertTips(tips: PatternWords[]) {
      this.numberList.forEach(num => {
        const tip = tips.find(value => value.pattern_start == num.key_number);
        if (tip) {
          num.left.has_tip = true;
          num.left.tip_desc = tip.pattern_desc + " " + tip.words
        }
      })
    }
  */

  selectLangLeft(langId: string) {
    console.log(langId);
    this.languageLeft = this.languageList.getById(langId);
    this.populateNumbersLeft("left");
  }

  selectLangRight(langId: string) {
    console.log(langId);
    this.languageRight = this.languageList.getById(langId);
    this.populateNumbersRight();
  }

  gotoPage(range: NumberRange) {
    this.refreshPage(range);
    const url = "/numbers/" + this.languageLeft.language_id + "/" + this.currentRange.start.toString();
    this.router.navigateByUrl(url)
    this.router.navigate([url])
  }

  gotoPreviousPage() {
    this.refreshPage(this.currentRange.previousRange());
    // this.gotoPage(this.currentRange.previousRange())
  }

  gotoNextPage() {
    this.refreshPage(this.currentRange.nextRange());
    // this.gotoPage(this.currentRange.nextRange())
  }

  public speakMale(words: TranslatedWords) {
    if (words) {
      words.audio_male_processing = true;
      if (words.has_audio_male) {
        if (words.audio_male) {
          this.playAudio(words, "male");
        } else {
          this.populateAudio(words, "male");
        }
      } else {
        this.processAudio(words, "male");
      }
    }
  }

  public speakFemale(words: TranslatedWords) {
    // console.log(this.currentWords)
    if (words) {
      words.audio_female_processing = true;
      if (words.has_audio_female) {
        if (words.audio_female) {
          this.playAudio(words, "female");
        } else {
          this.populateAudio(words, "female");
        }
      } else {
        this.processAudio(words, "female");
      }
    }
  }

  populateAudio(words: TranslatedWords, gender: string) {
    console.log("populating audio - " + gender);
    console.log(words);
    // words.language_id = this.languageLeft.language_id;
    this.nolApiSvc.getAudioById(words, gender).subscribe((data: TranslatedAudio[]) => {
      console.log(data);
      if (data && data.length > 0) {
        const audioString = data[0].audio_mp3;
        this.assignAudio(words, gender, audioString);
        this.playAudio(words, audioString);
      } else {
        console.error("Failed to get audio");
        words.audio_male_processing = false;
        words.audio_female_processing = false;
      }
    })
  }


  processAudio(words: TranslatedWords, gender: string) {
    // words.language_id = this.languageLeft.language_id;
    // words.language_code = this.languageLeft.language_code;
    this.nolApiSvc.processAudio(words, gender).subscribe((data: TranslatedAudio) => {
      if (data) {
        const audioString = data.audio_mp3;
        this.assignAudio(words, gender, audioString);
        this.playAudio(words, gender);
      } else {
        console.error("Failed to process audio");
        words.audio_male_processing = false;
        words.audio_female_processing = false;
      }
    })
  }

  assignAudio(words: TranslatedWords, gender: string, audio: string) {
    if (gender == "male") {
      words.has_audio_male = true;
      words.audio_male = audio;
    } else {
      words.has_audio_female = true;
      words.audio_female = audio;
    }
  }

  playAudio(words: TranslatedWords, gender: string) {
    const audioString = (gender == "male") ? words.audio_male : words.audio_female;
    // this.audioBase64 = null;
    this.audioBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:audio/wav;base64,' + audioString);
    // console.log(this.audioBase64);
    words.audio_male_processing = false;
    words.audio_female_processing = false;
  }

  translateLeft(row: TranslatedComparisonItem) {
    // console.log(row);
    row.processingLeft = true;
    let newWords = TranslatedWords.createFromLanguage(this.currentRange.key, row.number, this.languageLeft);
    this.nolApiSvc.processWords(newWords).subscribe((newData: TranslatedWords) => {
      if (newData) {
        // console.log(newData);
        newWords.words = newData.words;
        // row.left.words = newData.words;
        this.numberList.assignLeft(newWords);
        row.processingLeft = false;
      } else {
        // ToDo: Show error message
      }
    });
  }

  translateRight(row: TranslatedComparisonItem) {
    // console.log(row);
    row.processingRight = true;
    let newWords = TranslatedWords.createFromLanguage(this.currentRange.key, row.number, this.languageRight);
    // console.log(newWords)
    this.nolApiSvc.processWords(newWords).subscribe((newData: TranslatedWords) => {
      if (newData) {
        // console.log(newData);
        newWords.words = newData.words;
        // row.right.words = newData.words;
        this.numberList.assignRight(newWords);
        row.processingRight = false;
      } else {
        // ToDo: Show error message
      }
    });
  }
}
