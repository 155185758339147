import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Language } from 'src/app/models/language';
import { MixMatchRow, MixMatchCol } from 'src/app/models/mix-match';
import { NumberRange } from 'src/app/models/number-range';
import { PatternWords } from 'src/app/models/pattern-words';
import { TranslatedWords } from 'src/app/models/translated-words';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
  selector: 'app-instructors-details-page',
  templateUrl: './instructors-details-page.component.html',
  styleUrls: ['./instructors-details-page.component.scss']
})
export class InstructorsDetailsPageComponent implements OnInit {
  languageList: Language[];
  language: Language;
  listFirst9: PatternWords[];
  listFirstSet: PatternWords[];
  listTensAndPlus: PatternWords[];
  listHundAndPlus: PatternWords[];
  listThousAndPlus: PatternWords[];
  outputWords: TranslatedWords;

  mixAndMatch1: MixMatchRow;
  mixAndMatch2: MixMatchRow;
  mixAndMatch3: MixMatchRow;
  mixAndMatchOutput: MixMatchRow;

  constructor(private route: ActivatedRoute, private router: Router, private nolApiSvc: NolApiService) { }

  ngOnInit(): void {
    this.populateLanauges();
    // this.prepareMixMatch();
  }

  populateLanauges() {
    this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
      this.languageList = data;
      this.selectLanguage(this.languageList[0])
    })
  }

  selectLanguage(lang: Language) {
    this.language = lang;
    this.populateFirstSet("spanish");
    // this.populateFirstSet(this.language.language_id);
  }

  prepareMixMatch1() {
    this.mixAndMatch1 = new MixMatchRow(1);
    this.mixAndMatch2 = new MixMatchRow(2);
    this.mixAndMatch3 = new MixMatchRow(3);

    this.mixAndMatch1.addCol(1, this.listFirstSet);
    this.mixAndMatchOutput = this.mixAndMatch1;
    this.mixAndMatch1.showAll();
  }

  prepareMixMatch2() {
    this.mixAndMatch2.addCol(10, this.listTensAndPlus);
    this.mixAndMatch2.addCol(1, this.listFirst9);
    this.mixAndMatch2.hideAll();
  }

  prepareMixMatch3() {
    this.mixAndMatch3.addCol(100, this.listHundAndPlus);
    this.mixAndMatch3.addCol(10, this.listTensAndPlus);
    this.mixAndMatch3.addCol(1, this.listFirst9);
    this.mixAndMatch3.hideAll();
  }

  populateFirstSet(language_id: string) {
    this.nolApiSvc.getPatternWordsByRange(language_id, "first_set").subscribe((data: PatternWords[]) => {
      this.listFirstSet = data;
      let lastNum: number = this.listFirstSet[this.listFirstSet.length - 1].key_number;
      const plus = PatternWords.createNew("", -1, lastNum.toString() + "+", "next");
      this.listFirstSet.push(plus)
      this.prepareMixMatch1();
      this.populateFirst9(language_id);
    })
  }

  populateFirst9(language_id: string) {
    this.nolApiSvc.getPatternWordsByRange(language_id, "first_9").subscribe((data: PatternWords[]) => {
      this.listFirst9 = data;
      this.populateTensAndPlus(language_id);
    })
  }

  populateTensAndPlus(language_id: string) {
    this.nolApiSvc.getPatternWordsByRange(language_id, "tens_and_plus").subscribe((data: PatternWords[]) => {
      this.listTensAndPlus = data;
      const plus = PatternWords.createNew("", -1, "100+", "next");
      this.listTensAndPlus.push(plus)
      this.prepareMixMatch2();
      this.populateHundAndPlus(language_id);
    })
  }

  populateHundAndPlus(language_id: string) {
    this.nolApiSvc.getPatternWordsByRange(language_id, "hundreds_and_plus").subscribe((data: PatternWords[]) => {
      this.listHundAndPlus = data;
      const plus = new PatternWords();
      plus.key_number = -1;
      plus.words = "1000+"
      this.listHundAndPlus.push(plus);
      this.prepareMixMatch3();
    })
  }

  populateWords(num: number) {
    const range = new NumberRange(num);
    this.nolApiSvc.getTranslatedWordsById(this.language.language_id, range.key, num).subscribe((data: TranslatedWords[]) => {
      // console.log(data)
      if (data && data.length > 0) {
        this.outputWords = data[0]
      }
    })
  }

  findTotal(row: number, col: number, coll: PatternWords[], id: number) {
    const pat = coll.find(val => val.key_number == id);
    console.log(pat)
    let num = pat.key_number

    switch (row) {
      case 1:
        this.selectMixMatch1(num, pat.pattern_part, col);
        break;
      case 2:
        this.selectMixMatch2(num, pat.pattern_part, col);
        break;
      case 3:
        this.selectMixMatch3(num, pat.pattern_part, col);
        break;
    }
    this.mixAndMatchOutput.assignNumber(col, num);
    this.mixAndMatchOutput.calculateTotal();
    // console.log(this.rowTotal)
    this.populateWords(this.mixAndMatchOutput.total);
  }

  selectMixMatch1(num: number, part: string, col: number) {
    this.mixAndMatchOutput = this.mixAndMatch1;
    if (part == "entire") {
      // Row 1 will produce the output
      this.mixAndMatch1.showAll();
      this.mixAndMatch1.manageColumns(col, part);
      this.mixAndMatch2.hideAll();
      this.mixAndMatch3.hideAll();
    } else if (part == "next") {
      // ToDo: change the color
      this.mixAndMatch1.disable();
      this.mixAndMatch1.manageColumns(col, part);
      // Moving to Row 2
      this.mixAndMatch2.showAll();
      this.mixAndMatch3.hideAll();
    } else {
      // ToDo: change the color
      this.mixAndMatch1.disable();
      this.mixAndMatch1.manageColumns(col, part);
      // Moving to Row 2
      this.mixAndMatch2.showAll();
      this.mixAndMatch3.showAll();
    }
  }

  selectMixMatch2(num: number, part: string, col: number) {
    this.mixAndMatchOutput = this.mixAndMatch2;
    // this.mixAndMatch1.visible = true;
    if (part == "entire") {
      // Row 2 will product the output   
      this.mixAndMatch2.highlight();
      this.mixAndMatch2.manageColumns(col, part);
      // if (col == 10) {
      //   // Hinding ones list
      //   this.mixAndMatch2.hide1();
      // } else {
      //   // Showing ones list
      //   this.mixAndMatch2.show1();
      // }
      this.mixAndMatch3.hideAll();
    } else if (part == "prefix" || part == "suffix") {
      this.mixAndMatch2.highlight();
      this.mixAndMatch2.manageColumns(col, part);
      this.mixAndMatch3.hideAll();
    } else {
      // ToDo: change the color
      // this.mixAndMatch2.hide1();
      this.mixAndMatch2.disable();
      this.mixAndMatch2.manageColumns(col, part);
      // Moving Row 3
      this.mixAndMatch3.showAll();
    }
  }

  selectMixMatch3(num: number, part: string, col: number) {
    this.mixAndMatchOutput = this.mixAndMatch3;
    if (part == "entire") {

      // Row 3 will produce the output
      this.mixAndMatch3.highlight();
      this.mixAndMatch2.manageColumns(col, part);
      if (col == 100) {
        // this.mixAndMatch3.hide1();
        // this.mixAndMatch3.hide10();
      } else if (col == 10) {
        // Hinding ones list
        // this.mixAndMatch3.hide1();
      } else {
        // Showing ones list
        // this.mixAndMatch3.show1();
      }
    } else {
      // ToDo: change the color
      this.mixAndMatch3.disable();
    }
  }
}
