import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactForm } from '../models/contact-form';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  apiUrl = environment.email_api_url;
  apiKey = environment.email_api_key;
  contact_email_id = environment.contact_email_id;

  constructor(private http: HttpClient) { }

  submitContactForm(contForm: ContactForm) {

    var message = contForm.message + "<br><br> by <br>" + contForm.userName;

    var emailJson = {
      recipient: this.contact_email_id,
      sender: "contact@orbitsolve.com",   //contForm.userEmail
      subject: "Message-Feedback submitted from OrbitSolve Website " + contForm.userPhone,
      message: message + " from " + contForm.userEmail
    };

    console.log(emailJson);

    var httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': this.apiKey
      })
    };

    return this.http.post(this.apiUrl, emailJson, httpOptions);
  }
}
