export class PatternWords {
    constructor() {
        //
    }
    key_range: string;
    key_number: number;
    pattern_start: number;
    pattern_end: number;
    pattern_desc: string;
    words: string;
    pattern_label: string;
    pattern_part: string;
    phonetics_english: string;

    static createNew(range: string, num: number, words: string, patternPart: string) {
        const pat = new PatternWords();
        pat.key_range = range;
        pat.key_number = num;
        pat.words = words;
        pat.pattern_part = patternPart;
        return pat;
    }
}