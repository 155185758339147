import { Language } from "./language";

export class TranslatedWords {
    copnstructor() {
        this.key_range = "";
        this.key_number = -1;
    }
    language_id: string;
    language_code: string;
    english_words_code: string;
    key_range: string = "";
    key_number: number = -1;
    words: string = "";
    phonetics_english: string = null;
    has_audio_male: boolean = false;
    audio_male: string;
    audio_male_processing: boolean = false;
    has_audio_female: boolean = false;
    audio_female: string;
    audio_female_processing: boolean = false;
    has_tip: boolean = false;
    tip_desc: string;

    clear() {
        this.key_number = -1;
        this.words = null;
        this.has_audio_female = false;
        this.has_audio_male = false;
        this.audio_female = null;
        this.audio_male = null;
        this.phonetics_english = null;
        this.audio_female_processing = false;
        this.audio_male_processing = false;
        this.has_tip = false;
        this.tip_desc = null;
    }

    public static createFromLanguage(range: string, num: number, language: Language): TranslatedWords {
        let newWords = new TranslatedWords();
        newWords.key_range = (range) ? range : "";
        newWords.key_number = num;
        newWords.language_id = (language) ? language.language_id : "";
        newWords.language_code = (language) ? language.language_code : "";
        newWords.english_words_code = (language) ? language.english_words_code : "";
        return newWords;
    }
}