import { Language } from "./language";

export class LanguageList {
    default: Language;
    all: Language[];
    asian: Language[];
    indian: Language[];
    european: Language[];
    americas: Language[];
    middleeast: Language[];
    english: Language[];
    top9: Language[];
    others: Language[];
    groups: string[] = ["european", "indian", "asian", "americas", "middleeast", "english"]

    constructor(list: Language[]) {
        this.all = list;
        this.default = this.all[0];
        // this.indian = this.getIndian();
        // this.asian = this.getAsian();
        // this.european = this.getEuropean();
        this.top9 = this.getTop9();

        this.filterByGroups();
        this.english = this.filterAllEnglish();
        this.others = this.filterAllOthers();
    }

    filterByGroups() {
        this.groups.forEach(group => {
            this[group] = this.filterByGroup(group);
        })
    }

    private filterByGroup(groupId: string): Language[] {
        return this.all.filter(val => val.group_id == groupId);
    }


    // private getIndian(): Language[] {
    //     return this.all.filter(val => val.group_id == "indian");
    // }

    // private getAsian(): Language[] {
    //     return this.all.filter(val => val.group_id == "asian");
    // }

    private getTop9(): Language[] {
        return this.all.filter(val => (val.group_id == "indian" && val.language_id != "english-india"));
    }

    // private getEuropean(): Language[] {
    //     return this.all.filter(val => val.group_id == "european");
    // }

    private filterAllEnglish(): Language[] {
        return this.all.filter(val => val.language_name.startsWith("English"));
    }

    private filterAllOthers(): Language[] {
        return this.all.filter(val => !this.groups.includes(val.group_id));
    }

    getById(languageId: string) {
        return this.all.find(val => val.language_id == languageId)
    }

}