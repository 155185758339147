import { Component, OnInit } from '@angular/core';
import { ContactForm } from 'src/app/models/contact-form';
import { EmailService } from 'src/app/services/email.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    contUsForm: ContactForm;

    constructor(private emailSvc: EmailService) { }
  
    ngOnInit(): void {
      this.contUsForm = new ContactForm();  
    }
  
    submitContactForm(){
      // console.log(this.contUsForm);
      if(!this.contUsForm.userName){
        // this.contUsAlert.showError("Please enter your name");
        return;
      }
      if(!this.contUsForm.userEmail){
        // this.contUsAlert.showError("Please enter your email");
        return;
      }
      if(!this.contUsForm.message){
        // this.contUsAlert.showError("Please enter your message");
        return;
      }
      this.emailSvc.submitContactForm(this.contUsForm).subscribe(
        res => {
          if(res["isEmailSent"]){
            console.log("Email sent successfully");
            this.contUsForm = new ContactForm();
          }
          // this.contUsAlert.showSuccess("Thank you for contacting us... we will respond soon")
          // console.log(res);
        },
        err => {
          // this.contUsAlert.showError(err);
          console.log(err);
        }
      )
    }
  
}