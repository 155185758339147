import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LanguageDetails } from 'src/app/models/language-details';
import { NumberRange } from 'src/app/models/number-range';
import { NumberRangeCollection } from 'src/app/models/number-range-collection';
import { PatternWords } from 'src/app/models/pattern-words';
import { TranslatedAudio } from 'src/app/models/translated-audio';
import { TranslatedWords } from 'src/app/models/translated-words';
import { NolApiService } from 'src/app/services/nol-api.service';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';

@Component({
  selector: 'app-courses-details-page',
  templateUrl: './courses-details-page.component.html',
  styleUrls: ['./courses-details-page.component.scss']
})
export class CoursesDetailsPageComponent implements OnInit {
  language: LanguageDetails;
  ranges: NumberRangeCollection;
  // languageId: string;
  startNumber = 0;
  currentRange: NumberRange;
  numberList: TranslatedWords[];
  // patternTips: PatternTip[];
  audioBase64: SafeResourceUrl;
  languageList: LanguageList;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private nolApiSvc: NolApiService,
    private titleService: Title) { }

  ngOnInit(): void {
    // const start = null;
    // this.route.queryParams.subscribe(params => {
    //   console.log(params)
    //   this.language = params['language_id'];
    //   const start = params['start_number'];
    // });
    const langId = this.route.snapshot.paramMap.get('language_id')
    if (langId) {
      const start = this.route.snapshot.paramMap.get('start_number')
      if (start) {
        this.startNumber = parseInt(start);
      }
      this.currentRange = new NumberRange(this.startNumber);
      this.populateLanguageSingle(langId);
      this.populateLanguages();
    }
    // console.log(this.languageId);
    // console.log(this.startNumber)
  }

  populateLanguageSingle(langId: string) {
    this.nolApiSvc.getLanguageById(langId).subscribe((data: LanguageDetails[]) => {
      if (data && data.length > 0) {
        this.language = data[0];
        this.refreshPage();
        this.titleService.setTitle("Numbers in " + this.language.language_name + " [" + this.currentRange.rangeNoSpace + "]");
      }
      // console.log(this.language);
    })
  }

  populateLanguages() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    if (this.languageList && this.languageList.all.length > 0) {
      // process if any
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        this.languageList = new LanguageList(data);
        this.nolApiSvc.setCacheLanguageList(data);
      })
    }
  }

  refreshPage(newRange?: NumberRange) {
    if (newRange) {
      this.currentRange = newRange;
    }
    this.ranges = new NumberRangeCollection(this.currentRange);
    this.populateNumbers(this.language.language_id);
    // console.log(this.ranges);
  }

  populateNumbers(language_id: string) {
    this.nolApiSvc.getTranslatedWordsByRange(language_id, this.currentRange.key).subscribe((data: TranslatedWords[]) => {
      this.numberList = data;
      // console.log(data);
      if (this.currentRange.end <= 100) {
        this.populatePatternTipsTensPlus();
      }
    })
  }

  populatePatternTipsTensPlus() {
    this.nolApiSvc.getPatternTipsTensPlus(this.language.language_id).subscribe((data: PatternWords[]) => {
      // this.patternTips = data;
      this.insertTips(data);
      // console.log(data);
    })
  }

  insertTips(tips: PatternWords[]) {
    // console.log(tips)
    this.numberList.forEach(num => {
      const tip = tips.find(value => value.pattern_start == num.key_number);
      // console.log(tip)
      if (tip) {
        num.has_tip = true;
        num.tip_desc = tip.pattern_desc + " " + tip.words + " " + tip.phonetics_english
      }
    })
  }

  gotoPage(range: NumberRange) {
    this.refreshPage(range);
    const url = "/numbers/" + this.language.language_id + "/" + this.currentRange.start.toString();
    this.router.navigateByUrl(url)
    this.router.navigate([url])
  }

  gotoPreviousPage() {
    this.gotoPage(this.currentRange.previousRange())
  }

  gotoNextPage() {
    this.gotoPage(this.currentRange.nextRange())
  }

  public speakMale(words: TranslatedWords) {
    console.log(words);
    if (words) {
      words.audio_male_processing = true;
      if (words.has_audio_male) {
        if (words.audio_male) {
          console.log("paying male audio");
          this.playAudio(words, "male");
        } else {
          console.log("populating male audio");
          this.populateAudio(words, "male");
        }
      } else {
        console.log("processing male audio");
        this.processAudio(words, "male");
      }
    }
  }

  public speakFemale(words: TranslatedWords) {
    console.log(words);
    if (words) {
      words.audio_female_processing = true;
      if (words.has_audio_female) {
        if (words.audio_female) {
          console.log("paying female audio");
          this.playAudio(words, "female");
        } else {
          console.log("populating female audio");
          this.populateAudio(words, "female");
        }
      } else {
        console.log("processing female audio");
        this.processAudio(words, "female");
      }
    }
  }

  populateAudio(words: TranslatedWords, gender: string) {
    console.log("populating audio - " + gender);
    console.log(words);
    words.language_id = this.language.language_id;
    this.nolApiSvc.getAudioById(words, gender).subscribe((data: TranslatedAudio[]) => {
      console.log(data);
      if (data && data.length > 0) {
        const audioString = data[0].audio_mp3;
        this.assignAudio(words, gender, audioString);
        this.playAudio(words, audioString);
      } else {
        console.error("Failed to get audio");
        words.audio_male_processing = false;
        words.audio_female_processing = false;
      }
    })
  }


  processAudio(words: TranslatedWords, gender: string) {
    words.language_id = this.language.language_id;
    words.language_code = this.language.language_code;
    this.nolApiSvc.processAudio(words, gender).subscribe((data: TranslatedAudio) => {
      if (data) {
        const audioString = data.audio_mp3;
        this.assignAudio(words, gender, audioString);
        this.playAudio(words, gender);
      } else {
        console.error("Failed to process audio");
        words.audio_male_processing = false;
        words.audio_female_processing = false;
      }
    })
  }

  assignAudio(words: TranslatedWords, gender: string, audio: string) {
    if (gender == "male") {
      words.has_audio_male = true;
      words.audio_male = audio;
    } else {
      words.has_audio_female = true;
      words.audio_female = audio;
    }
  }

  playAudio(words: TranslatedWords, gender: string) {
    const audioString = (gender == "male") ? words.audio_male : words.audio_female;
    // this.audioBase64 = null;
    this.audioBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:audio/wav;base64,' + audioString);
    // console.log(this.audioBase64);
    words.audio_male_processing = false;
    words.audio_female_processing = false;
  }

}
