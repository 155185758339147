import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
    selector: 'app-header-style-one',
    templateUrl: './header-style-one.component.html',
    styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {
    languageList: LanguageList;

    constructor(private router: Router, private nolApiSvc: NolApiService) { }

    ngOnInit(): void {
        this.populateLanauges();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    populateLanauges() {
        this.languageList = this.nolApiSvc.getLanguagesFromCache();
        if (this.languageList && this.languageList.all.length > 0) {
            // process if any
        } else {
            this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
                this.languageList = new LanguageList(data);
                this.nolApiSvc.setCacheLanguageList(data);
            })
        }
    }

    gotoPage(lang: Language) {
        const url = "/numbers/" + lang.language_id;
        this.router.navigateByUrl(url);
        this.router.navigate([url]);
    }
}