import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { transcode } from 'buffer';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { NumberRange } from 'src/app/models/number-range';
import { TranslatedAudio } from 'src/app/models/translated-audio';
import { TranslatedWords } from 'src/app/models/translated-words';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
  selector: 'app-homefive-main-banner',
  templateUrl: './homefive-main-banner.component.html',
  styleUrls: ['./homefive-main-banner.component.scss']
})
export class HomefiveMainBannerComponent implements OnInit {
  // private convertToWords: any;
  // languageList: Language[] = [];
  languageList: LanguageList;
  selectedLanguage: Language;
  // audioString = "";
  audioBase64: SafeResourceUrl;
  inputNum: number = 123;
  lastInputNum: number = 0;
  numAsEnglishWords: string = "";
  numAsWordsTranslated: string = "";
  wordLanguageCode: string = "en-IN";
  audioLanguageCode: string = "hi-IN"
  // languageCode: string;
  lastLanguageCode: string = "";
  countryCode: string = "";
  localeCode: string = "";
  currentWords: TranslatedWords;
  translating: boolean = false;

  constructor(private sanitizer: DomSanitizer, private nolApiSvc: NolApiService) { }

  ngOnInit(): void {
    this.populateLanauges();
  }

  populateLanauges() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    if (this.languageList && this.languageList.all.length > 0) {
      this.selectLanguage(this.languageList.default)
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        this.languageList = new LanguageList(data);
        this.selectLanguage(this.languageList.default)
        // console.log(this.languageList)
      })
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  clearVariables() {
    this.currentWords = undefined;
    this.numAsEnglishWords = null;
    this.numAsWordsTranslated = null;
    // this.audioString = null;
    this.audioBase64 = null;
    this.lastInputNum = 0;
    this.lastLanguageCode = "";
  }
  /*
    prepareLanguageCodes(lang: string) {
      const languageCodeArray = lang.split("-");
      // console.log(languageCodeArray);
      this.languageCode = languageCodeArray[0];
      if (languageCodeArray.length > 1) {
        this.countryCode = languageCodeArray[1];
      } else {
        this.countryCode = "US";
      }
      this.localeCode = "en-" + this.countryCode;
    }
  */
  selectLanguageById(langId: string) {
    const lang = this.languageList.getById(langId);
    this.selectLanguage(lang);
  }
  selectLanguage(lang: Language) {
    // console.log(lang);
    this.clearVariables();
    this.selectedLanguage = lang;
    if (this.currentWords) {
      this.currentWords.language_id = this.selectedLanguage.language_id;
      this.currentWords.language_code = this.selectedLanguage.language_code;
      this.sayNumber();
    } else {
      this.currentWords = new TranslatedWords();
      this.currentWords.key_range = "range_0_50";
      this.currentWords.key_number = 123;
      this.currentWords.language_id = this.selectedLanguage.language_id;
      this.currentWords.language_code = this.selectedLanguage.language_code;
      this.sayNumber();
    }
  }

  public speakMale() {
    if (this.currentWords) {
      this.currentWords.audio_male_processing = true;
      if (this.currentWords.has_audio_male) {
        if (this.currentWords.audio_male) {
          this.playAudio(this.currentWords.audio_male);
        } else {
          this.populateAudio("male");
        }
      } else {
        this.processAudio("male");
      }
    }
  }

  public speakFemale() {
    // console.log(this.currentWords)
    if (this.currentWords) {
      this.currentWords.audio_female_processing = true;
      if (this.currentWords.has_audio_female) {
        if (this.currentWords.audio_female) {
          this.playAudio(this.currentWords.audio_female);
        } else {
          this.populateAudio("female");
        }
      } else {
        this.processAudio("female");
      }
    }
  }

  populateAudio(gender: string) {
    if (this.currentWords) {
      console.log("populating audio - " + gender);
      this.nolApiSvc.getAudioById(this.currentWords, gender).subscribe((data: TranslatedAudio[]) => {
        // console.log(data);
        if (data && data.length > 0) {
          const audioString = data[0].audio_mp3;
          this.assignAudio(gender, audioString);
          this.playAudio(audioString);
        } else {
          console.error("Failed to get audio");
          this.currentWords.audio_female_processing = false;
          this.currentWords.audio_male_processing = false;
        }
      })
    }
  }


  processAudio(gender: string) {
    if (this.currentWords) {
      this.nolApiSvc.processAudio(this.currentWords, gender).subscribe((data: TranslatedAudio) => {
        if (data) {
          const audioString = data.audio_mp3;
          this.playAudio(audioString);
          this.assignAudio(gender, audioString);
        } else {
          console.error("Failed to process audio");
          this.currentWords.audio_female_processing = false;
          this.currentWords.audio_male_processing = false;
        }
      })
    }
  }

  assignAudio(gender: string, audio: string) {
    if (this.currentWords) {
      if (gender == "male") {
        this.currentWords.has_audio_male = true;
        this.currentWords.audio_male = audio;
      } else {
        this.currentWords.has_audio_female = true;
        this.currentWords.audio_female = audio;
      }
    }
  }

  playAudio(audioString: string) {
    if (this.currentWords) {
      // this.audioBase64 = null;
      this.audioBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:audio/wav;base64,' + audioString);
      // console.log(this.audioBase64);
      this.currentWords.audio_female_processing = false;
      this.currentWords.audio_male_processing = false;
    }
  }

  public sayNumber() {
    // this.numAsEnglishWords = num.toString()
    // const num = parseInt(this.inputNum)
    // console.log(this.inputNum)
    // console.log(this.inputNum);
    if (!this.inputNum || !this.inputNum == null || !Number(this.inputNum) || this.inputNum.toString().trim() == "") {
      this.clearVariables();
      this.numAsWordsTranslated = "Invalid number!!!";
      this.currentWords = undefined;
    } else {
      // this.nolApiSvc.translateNumber(this.inputNum.toString(), this.wordLanguageCode, this.audioLanguageCode).subscribe((data: {}) => {
      //   console.log(data);
      //   // this.topCategories = data;
      //   this.numAsEnglishWords = data["words_in_english"]
      //   this.numAsWordsTranslated = data["words_in_language"]
      //   this.audioString = data["audio_female_mp3"]
      //   this.audioBase64 = this.sanitizer.bypassSecurityTrustResourceUrl('data:audio/wav;base64,' + this.audioString);
      // })
      // console.log(range)
      if (this.selectedLanguage) {
        if (this.inputNum > 9999999999 && !this.selectedLanguage.language_id.startsWith("english")) {
          this.numAsWordsTranslated = "Sorry, number above 9999999999 cannot be translated!!!";
          this.currentWords = undefined;
          return;
        }
        // if (this.inputNum <= this.selectedLanguage.words_ready_upto) {
        const range = new NumberRange(this.inputNum);
        this.nolApiSvc.getTranslatedWordsById(this.selectedLanguage.language_id, range.key, this.inputNum).subscribe((data: TranslatedWords[]) => {
          // console.log(data)
          if (data && data.length > 0) {
            let newWords = TranslatedWords.createFromLanguage(range.key, this.inputNum, this.selectedLanguage);
            newWords.words = data[0].words;
            // this.currentWords.language_id = this.selectedLanguage.language_id;
            // this.currentWords.language_code = this.selectedLanguage.language_code;
            // this.currentWords.key_range = newRange.key;
            // this.currentWords.key_number = this.inputNum;            
            this.displayWords(newWords);
          } else {
            this.currentWords = undefined;
            this.numAsWordsTranslated = "Please click Translate button";
          }
        })
      }

    }
  }

  translate() {
    if (this.currentWords) {
      return;
    }
    this.translating = true;
    const range = new NumberRange(this.inputNum);
    let newWords = TranslatedWords.createFromLanguage(range.key, this.inputNum, this.selectedLanguage);
    this.nolApiSvc.processWords(newWords).subscribe((newData: TranslatedWords) => {
      this.translating = false;
      if (newData) {
        newWords.words = newData.words;
        this.displayWords(newWords)
      } else {
        this.numAsWordsTranslated = "Currently not available!!!";
        this.currentWords = undefined;
      }
    });
  }

  displayWords(newWords: TranslatedWords) {
    // console.log(newWords);
    this.currentWords = newWords;
    this.numAsWordsTranslated = this.currentWords.words;
    this.lastInputNum = this.inputNum;
    this.lastLanguageCode = this.audioLanguageCode;
  }


}
