import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TranslatedWords } from '../models/translated-words';
import { NumberRange } from '../models/number-range';
import { LanguageDetails } from '../models/language-details';
import { PatternWords } from '../models/pattern-words';
import { Language } from '../models/language';
import { TranslatedAudio } from '../models/translated-audio';
import { LanguageList } from '../models/language-list';

@Injectable({
  providedIn: 'root'
})
export class NolApiService {
  nolApiBaseUrl: string;
  languageList: LanguageList;
  languageListIndian: Language[];

  constructor(private http: HttpClient) {
    this.nolApiBaseUrl = environment.nolApiBaseUrl
    // this.refreshCache();
  }

  // refreshCache() {
  //   this.getLanguages().subscribe((data: Language[]) => {
  //     this.languageList = data;
  //     // console.log(this.languageList)
  //   })
  // }

  setCacheLanguageList(list: Language[]): LanguageList {
    this.languageList = new LanguageList(list);
    return this.languageList;
  }

  getLanguagesFromCache() {
    return this.languageList
  }

  getLanguages() {
    return this.http.get<any>(this.nolApiBaseUrl + "/languages/published")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getLanguageById(langId: string) {
    const url = this.nolApiBaseUrl + "/languages/id/" + langId;
    return this.http.get<LanguageDetails[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  /*
    translateNumber(inputNum: string, words_language: string, audio_language: string) {
      // console.log(typeof (inputNum))
      const num = parseInt(inputNum)
      const body = {
        number: num,
        words_language,
        audio_language
      }
      console.log(body)
      return this.http.post<any>(this.nolApiBaseUrl + "/translate", body)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
    }
  */
  getPatternWordsByRange(lang: string, rangeKey: string) {
    const url = this.nolApiBaseUrl + "/translated-words/" + lang + "/" + rangeKey
    return this.http.get<PatternWords[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getTranslatedWordsByRange(lang: string, rangeKey: string) {
    const url = this.nolApiBaseUrl + "/translated-words/" + lang + "/" + rangeKey
    return this.http.get<TranslatedWords[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getTranslatedWordsById(lang: string, rangeKey: string, num: number) {
    const url = this.nolApiBaseUrl + "/translated-words/" + lang + "/" + rangeKey + "/" + num.toString();
    return this.http.get<TranslatedWords[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getAudioById(words: TranslatedWords, gender: string) {
    const lang = words.language_id;
    const rangeKey = words.key_range;
    const num = words.key_number;
    const url = this.nolApiBaseUrl + "/audio-" + gender + "/" + lang + "/" + rangeKey + "/" + num.toString();
    // console.log(url);
    return this.http.get<TranslatedAudio[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  processWords(words: TranslatedWords) {
    const url = this.nolApiBaseUrl + "/process-words";
    const numStr: string = words.key_number.toString();
    let body = {
      number: parseInt(numStr),
      language_code: words.language_code,
      language_id: words.language_id,
      english_words_code: words.english_words_code
    }
    // console.log(body);
    return this.http.post<TranslatedWords>(url, body);
  }

  processAudio(words: TranslatedWords, voiceGender: string) {
    console.log(voiceGender);
    const url = this.nolApiBaseUrl + "/process-audio";
    const numStr: string = words.key_number.toString();
    let englishWords = null;
    if (words.language_code.startsWith("en-")) {
      englishWords = words.words;
    }
    let body = {
      number: parseInt(numStr),
      text: englishWords,
      language_code: words.language_code,
      language_id: words.language_id,
      gender: voiceGender
    }
    return this.http.post<TranslatedAudio>(url, body);
  }

  getPatternTipsTensPlus(lang: string) {
    const url = this.nolApiBaseUrl + "/translated-words/" + lang + "/tens_plus"
    // console.log(url)
    return this.http.get<PatternWords[]>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // Error handling 
  handleError(error: any) {
    // console.log(error)
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
