import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Language } from 'src/app/models/language';
import { LanguageList } from 'src/app/models/language-list';
import { NumberRange } from 'src/app/models/number-range';
import { TranslatedWords } from 'src/app/models/translated-words';
import { NolApiService } from 'src/app/services/nol-api.service';

@Component({
  selector: 'app-flash-cards',
  templateUrl: './flash-cards.component.html',
  styleUrls: ['./flash-cards.component.scss']
})
export class FlashCardsComponent implements OnInit {
  languageList: LanguageList;
  currentLanguage: Language;
  currentNumber: TranslatedWords;
  currentSet: TranslatedWords[];
  currentRange: NumberRange;
  setSize: number = 9;

  constructor(private route: ActivatedRoute, private router: Router, private nolApiSvc: NolApiService) {
    this.populateLanguages();
  }

  ngOnInit(): void {
    // this.populateLanguages();
  }

  populateLanguages() {
    this.languageList = this.nolApiSvc.getLanguagesFromCache();
    // console.log(this.languageList);
    if (this.languageList && this.languageList.all.length > 0) {
      this.selectLanguage(this.languageList.all[0].language_id);
    } else {
      this.nolApiSvc.getLanguages().subscribe((data: Language[]) => {
        // console.log(data)
        this.languageList = this.nolApiSvc.setCacheLanguageList(data);
        this.selectLanguage(this.languageList.all[0].language_id);
      })
    }
  }

  selectLanguage(langId: string) {
    this.currentLanguage = this.languageList.getById(langId);
    this.showFirstSet();
  }

  showFirstSet() {
    this.populateSet(1);
  }

  // showNextCard() {
  //   this.populateWords(this.currentNumber.key_number + 1)
  // }

  // showPreviousCard() {
  //   if (this.currentNumber.key_number > 0) {
  //     this.populateSet(this.currentNumber.key_number - 1)
  //   }
  // }

  populateSet(startNum: number) {
    let endNum = startNum + this.setSize;
    this.currentRange = new NumberRange(startNum);
    this.nolApiSvc.getTranslatedWordsByRange(this.currentLanguage.language_id, this.currentRange.key).subscribe((data: TranslatedWords[]) => {
      this.currentSet = [];
      for (let i = startNum; i < endNum; i++) {
        let item = data.find(val => (val.key_number == i));
        this.currentSet.push(item)
      }
      // console.log(this.currentSet)
    });
  }


  // populateWords(nextNum: number) {
  //   if (this.currentLanguage) {
  //     const range = new NumberRange(nextNum);
  //     this.currentNumber = TranslatedWords.createFromLanguage(range.key, nextNum, this.currentLanguage);
  //     // this.currentNumber = newWords;
  //     this.nolApiSvc.getTranslatedWordsById(this.currentLanguage.language_id, range.key, nextNum).subscribe((data: TranslatedWords[]) => {
  //       // console.log(data)
  //       if (data && data.length > 0) {
  //         this.currentNumber.words = data[0].words;
  //       } else {
  //         this.currentNumber.words = null;
  //         // this.currentNumber = undefined;
  //         // this.numAsWordsTranslated = "Please click Translate button";
  //       }
  //     })
  //   }
  // }



}
