<app-header-style-one></app-header-style-one>

<!-- <div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="courses-2-columns-style-1.html">Courses</a></li>
                <li>Translated Numbers in Malayalam</li>
            </ul>
            <h2>Courses</h2>
        </div>
    </div>
</div> -->

<div class="courses-details-area pt-100 pb-70">
    <div class="container" *ngIf="language">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>Numbers in {{language.language_name}} words</h2>
                        <p *ngIf="language.language_id != 'malayalam'">{{language.short_description}}</p>
                        <p *ngIf="language.language_id == 'malayalam'"><a href='https://ling-app.com/learn-malayalam/'
                                target='_blank'>Malayalam</a> (/ˌmaləˈyäləm/) is a Dravidian language spoken mainly in
                            the southern Indian state of Kerala, closely related to Tamil.</p>
                        <p>{{language.translation_rules}}</p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Language</span>
                                <a>{{language.language_name}}</a>
                            </li>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Country</span>
                                <a>{{language.country_name}}</a>
                            </li>
                            <!-- <li>
                                <i class='bx bx-group'></i>
                                <span>Numbers Processed</span>
                                <a>{{language.words_ready_upto}} & Counting</a>
                            </li> -->
                            <!-- <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses">10/23/2022</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="courses-price">
                        <!-- <div class="courses-review">
                            <div class="review-stars">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <span class="reviews-total d-inline-block">(2 reviews)</span>
                        </div>
                        <div class="price">$250</div> -->
                        <!-- <a routerLink="/translator" class="default-btn"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Try
                                Translator</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center" *ngIf="language.banner_image_src">
                    <img src="{{language.banner_image_src}}"
                        alt="{{language.language_name}}, {{language.country_name}}">
                    <small *ngIf="language.banner_image_credits" style="float: right;">Image &copy; <a
                            href="{{language.banner_image_credits_link}}"
                            target="_blank">{{language.banner_image_credits_name}}</a></small>
                </div>
                <div class="courses-details-desc">
                    <h4>Here are the numbers in words between {{currentRange.start}} and {{currentRange.end}} translated
                        to {{language.language_name}}</h4>
                    <div class="why-you-learn">

                        <audio #player autobuffer="autobuffer" autoplay="autoplay" [src]="audioBase64"
                            style="width: 80%;">
                            Your browser does not support the audio element / audio format.
                        </audio>

                        <table class="table nol-table">
                            <!-- <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{{language.language_name}} Translation</th>
                                    <th scope="col">Speak</th>
                                </tr>
                            </thead> -->
                            <tbody>
                                <tr *ngFor="let item of numberList">
                                    <td>
                                        {{item.key_number}}
                                    </td>
                                    <td>
                                        <!-- <div style="width:50%; float:left;">{{item.words}}</div> -->
                                        <div class="float-xl-left">{{item.words}}</div>
                                        <div class="float-xl-left"><span
                                                class="phonetics">{{item.phonetics_english}}</span></div>
                                        <span *ngIf="item.has_tip">
                                            <small style="color:read;"><i>(Hint: {{item.tip_desc}})</i></small>
                                        </span>
                                    </td>
                                    <td>
                                        <img *ngIf="item.audio_female_processing" src="assets/images/process.gif"
                                            width="20" height="20" alt="...">
                                        <img *ngIf="!item.audio_female_processing" src="assets/images/speaker-pink.png"
                                            width="20" height="20" alt="Speak" (click)="speakFemale(item)"
                                            style="cursor:pointer;">
                                    </td>
                                    <!-- <td>
                                        <img *ngIf="item.audio_male_processing" src="assets/images/process.gif"
                                            width="20" height="20" alt="...">
                                        <img *ngIf="!item.audio_male_processing" src="assets/images/speaker-blue.png"
                                            width="20" height="20" alt="Speak" (click)="speakMale(item)"
                                            style="cursor:pointer;">
                                    </td> -->
                                    <!-- <td>
                                        <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>

                        <!-- <button (click)="gotoNextPage()" >Next</button> -->
                        <!-- <a routerLink="/numbers/hi-in/401" class="default-btn"><i -->
                        <a (click)="gotoPreviousPage()" class="default-btn" type="submit"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Previous</span><i
                                class="bx bx-paper-plane icon-arrow after"></i></a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a (click)="gotoNextPage()" class="default-btn" type="submit"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Next</span><i
                                class="bx bx-paper-plane icon-arrow after"></i></a>
                        <br>
                    </div>
                </div>
                <!-- <div class="related-courses">
                    <h3>Related Courses</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img
                                            src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT
                                            Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img
                                            src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design
                                            with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <a href="https://www.dpbolvw.net/click-101154220-15150693" target="_top">
                        <img src="https://www.ftjcfx.com/image-101154220-15150693" width="300" height="250"
                            alt="Learn a new language from home - get Lifetime Access to Mondly with 95% OFF!"
                            border="0" /></a>
                </div>
                <div class="courses-sidebar-information">
                    <h3>Next set of numbers</h3>
                    <ul>
                        <li *ngFor="let eachRange of ranges.next5">
                            <a (click)="gotoPage(eachRange)" style="cursor:pointer;">
                                <span>{{eachRange.range}}</span></a>
                        </li>
                    </ul>
                </div>
                <div class="courses-sidebar-syllabus">
                    <h3>Tools</h3>
                    <div class="courses-list">
                        <ul>
                            <li><a routerLink="/tools/compare" target="_blank">Compare 2 Languages </a></li>
                            <li><a routerLink="/tools/interactive" target="_blank">Generate by Pattern </a></li>
                        </ul>
                    </div>
                </div>
                <div class="courses-sidebar-information">
                    <a href="https://www.kqzyfj.com/click-101154220-15150709" target="_top">
                        <img src="https://www.lduhtrp.net/image-101154220-15150709" width="336" height="280"
                            alt="Learn a new language from home - get Lifetime Access to Mondly with 95% OFF!"
                            border="0" /></a>
                </div>

                <div class="courses-sidebar-syllabus" *ngIf="languageList">
                    <h3>Other Languages</h3>
                    <span class="chapter">Indian</span>
                    <!-- <h4>Lessons</h4> -->
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.indian">
                                <a routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">Europian</span>
                    <!-- <h4>Lessons</h4> -->
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.european"> <a
                                    routerLink="/numbers/{{lang.language_id}}" target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                        </ul>
                    </div>
                    <span class="chapter">English</span>
                    <!-- <h4>Final Quiz</h4> -->
                    <div class="courses-list">
                        <ul>
                            <li *ngFor="let lang of languageList.english"> <a routerLink="/numbers/{{lang.language_id}}"
                                    target="_blank">
                                    {{lang.language_name}}</a>
                            </li>
                            <!-- <li>English (US)</li>
                            <li>English (Australia)</li>
                            <li>English (UK)</li>
                            <li>English (India)</li> -->
                        </ul>
                    </div>
                </div>
                <div class="courses-purchase-info">
                    <h4>Like to translate more numbers?</h4>
                    <p>You can translate any number to any language using our Live Translator.</p>
                    <a routerLink="/translator" class="default-btn"><i
                            class='bx bx-paper-plane icon-arrow before'></i><span class="label">Try
                            Translator</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>